import axios from 'axios'

const baseURL = process.env.VUE_APP_DROP_ORDER_SERVICE + '/api/admin/withdraws'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async getWithdraws ({
      state,
      commit
    }, filters) {
      switch (parseInt(filters.sort)) {
        case 1:
          filters.order_by = 'created_at'
          filters.order_direction = 'asc'
          break
        case 2:
          filters.order_by = 'created_at'
          filters.order_direction = 'desc'
          break
      }
      return axios.get(baseURL, {
        params: filters
      })
    },
    async getWithdraw ({
      state,
      commit
    }, id) {
      const promise = axios.get(baseURL + '/' + id)
      promise.then(response => {
        commit('setWithdraw', response.data.data)
      })
      return promise
    },
    async approves ({
      state,
      commit
    }, data) {
      return axios.post(baseURL + '/' + data.withdraw_id + '/withdraws')
    },
    async declines ({
      state,
      commit
    }, data) {
      return axios.delete(baseURL + '/' + data.withdraw_id + '/withdraws')
    }
  }
}
