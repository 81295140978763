import axios from 'axios'

const baseURL = process.env.VUE_APP_DROP_ORDER_SERVICE + '/api/admin/payment-contragents'

export default {
  namespaced: true,
  state: {
    is_load: false,
    items: [],
    item: null
  },
  mutations: {
    setItems (state, payload) {
      state.items = payload
    },
    setItem (state, payload) {
      state.item = payload
    }
  },
  actions: {
    async getItems ({ state, commit }, type) {
      state.is_load = true
      commit('setItems', [])
      const promise = axios.get(baseURL, {
        params: {
          type: type,
          per_page: 30
        }
      })
      promise.then(response => {
        commit('setItems', response.data.data)
        state.is_load = false
      })
      return promise
    },
    async createItem ({
      state,
      commit
    }, data) {
      const promise = axios.post(baseURL, data)
      promise.then(response => {
        commit('setItem', response.data.data)
      })
      return promise
    },
    async updateItem ({
      state,
      commit
    }, data) {
      const promise = axios.put(baseURL + '/' + data.id, data)
      promise.then(response => {
        commit('setItem', response.data.data)
      })
      return promise
    },
    async deleteItem ({
      state,
      commit
    }, data) {
      return axios.delete(baseURL + '/' + data.id)
    }
  }
}
