import axios from 'axios'

const baseURL = process.env.VUE_APP_DROP_ORDER_SERVICE + '/api/admin/users'

export default {
  namespaced: true,
  state: {
    client: null
  },
  mutations: {
    setClient (state, payload) {
      state.client = payload
    }
  },
  actions: {
    async getClients ({
      state,
      commit
    }, filters) {
      switch (parseInt(filters.sort)) {
        case 1:
          filters.order_by = 'created_at'
          filters.order_direction = 'asc'
          break
        case 2:
          filters.order_by = 'created_at'
          filters.order_direction = 'desc'
          break
      }
      return axios.get(baseURL, {
        params: filters
      })
    },
    async getClient ({
      state,
      commit
    }, id) {
      const promise = axios.get(baseURL + '/' + id)
      promise.then(response => {
        commit('setClient', response.data.data)
      })
      return promise
    },
    async clientBalance ({
      state,
      commit
    }, filters) {
      return axios.get(baseURL + '/' + filters.user_id + '/balance', {
        params: filters
      })
    },
    async createCorrection ({
      state,
      commit
    }, data) {
      return axios.post(baseURL + '/' + data.user_id + '/correction', data)
    },
    async updateClient ({
      state,
      commit
    }, data) {
      return axios.put(baseURL + '/' + data.id, data)
    },
    async clientWithdraws ({
      state,
      commit
    }, filters) {
      return axios.get(baseURL + '/' + filters.user_id + '/withdraws', {
        params: filters
      })
    },
    async approveWithdraws ({
      state,
      commit
    }, data) {
      return axios.post(baseURL + '/' + data.withdraw_id + '/withdraws')
    },
    async declineWithdraws ({
      state,
      commit
    }, data) {
      return axios.delete(baseURL + '/' + data.withdraw_id + '/withdraws')
    }
  }
}
