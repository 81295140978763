import axios from 'axios'
import router from '@/router'
import { eventBus } from '@/main'

// const baseURL = process.env.VUE_APP_AUTH_SERVER
const baseURL = process.env.VUE_APP_USER_SERVER

export default {
  namespaced: true,
  state: {
    is_loading: false,
    clientList: [],
    client: null,
    list_per_page: [10, 20, 30, 50, 100],
    clients_pagination: {
      current_page: 1,
      per_page: 10,
      total: 0
    },
    filters: {
      search: null,
      order_by: 'id',
      order_direction: 'desc'
    }
  },
  mutations: {
    setClients (state, payload) {
      state.clientList = payload
    },
    setClientPage (state, page) {
      state.clients_pagination.current_page = page
    },
    setClientPerPage (state, payload) {
      state.clients_pagination.per_page = payload
      localStorage.setItem('etalon-admin-clients-per-page', payload)
    },
    setClientPagination (state, payload) {
      state.clients_pagination = payload
    },
    setIsLoading (state, v) {
      state.is_loading = v
    },
    setClientSearch (state, v) {
      state.filters.search = v
    },
    setSorting (state, data) {
      state.filters.order_by = data.order_by || 'id'
      state.filters.order_direction = data.order_direction ? 'asc' : 'desc'
    },
    setClient (state, data) {
      state.client = data
    }
  },
  actions: {
    async getClients ({
      state,
      commit
    }) {
      commit('setIsLoading', true)
      const promise = axios.get(baseURL + '/api/admin/users', {
        params: {
          search: state.filters.search,
          page: state.clients_pagination.current_page,
          per_page: state.clients_pagination.per_page,
          order_by: state.filters.order_by,
          order_direction: state.filters.order_direction
        }
      })

      promise.then(response => {
        commit('setClients', response.data.data)
        commit('setClientPagination', response.data.meta)
        commit('setIsLoading', false)
      }).catch(r => {
        commit('setIsLoading', false)
      })

      return promise
    },
    async getClient ({
      state,
      commit
    }, id) {
      commit('setIsLoading', true)
      const promise = axios.get(baseURL + '/api/admin/users/' + id)

      promise.then(response => {
        commit('setClient', response.data.data)
        commit('setIsLoading', false)
      }).catch(r => {
        commit('setIsLoading', false)
      })

      return promise
    },
    async createClient ({ commit }, data) {
      commit('setIsLoading', true)
      const promise = axios.post(baseURL + '/api/admin/users', {
        name: data.name,
        password: data.password,
        user_code_id: data.user_code_id,
        login: data.user_code_id,
        phone: data.phone,
        email: data.email,
        address: data.address,
        is_online: data.is_online,
        only_show: data.only_show,
        min_total: parseFloat(data.min_total)
      })

      promise.then(response => {
        commit('setIsLoading', false)
        router.push('/dashboard/clients')
      }).catch(e => {
        commit('setIsLoading', false)
        eventBus.$root.$emit(
          'send_notify',
          'error',
          'Ошибка',
          'Не удалось создать клиента'
        )
      })

      return promise
    },
    async updateClient ({ commit }, data) {
      commit('setIsLoading', true)
      const promise = axios.put(baseURL + '/api/admin/users/' + data.id, {
        name: data.name,
        password: data.password,
        phone: data.phone,
        email: data.email,
        address: data.address,
        is_online: data.is_online,
        only_show: data.only_show,
        min_total: parseFloat(data.min_total)
      })

      promise.then(response => {
        commit('setIsLoading', false)
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Успешно обновлено',
          'Данные клиента обновлены'
        )
        router.push('/dashboard/clients')
      }).catch(e => {
        commit('setIsLoading', false)
        eventBus.$root.$emit(
          'send_notify',
          'error',
          'Ошибка',
          'Не удалось обновить клиента'
        )
      })

      return promise
    }
  }
}
