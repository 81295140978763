<script>
import { mapState } from 'vuex'

export default {
  name: 'NewYearComponent',
  data () {
    return {
      showMessage: false
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    closeMessage () {
      this.showMessage = false
      // Устанавливаем флаг в LocalStorage
      localStorage.setItem('newYearMessageSeen', 'true')
    },
    runShow () {
      // Генерация снежинок каждые 300 мс
      setInterval(this.createSnowflake, 200)
    },
    // Функция для создания снежинки
    createSnowflake () {
      const cloud = document.querySelector('.cloud')
      const snowflake = document.createElement('div')

      if (cloud) {
        snowflake.classList.add('snowflake')

        // Случайная горизонтальная позиция внутри ширины облака
        const startX = Math.random() * 400 // Немного шире облака
        snowflake.style.left = `${startX}px`

        // Случайная продолжительность анимации
        const duration = Math.random() * 2 + 7 // От 2 до 5 секунд
        snowflake.style.animationDuration = `${duration}s`

        // Добавляем снежинку к облаку
        cloud.appendChild(snowflake)

        // Удаляем снежинку после завершения анимации
        snowflake.addEventListener('animationend', () => {
          snowflake.remove()
        })
      }
    }
  },
  mounted () {
    // Проверяем текущую дату
    const now = new Date()
    const start = new Date('2024-12-25')
    const end = new Date('2025-01-14')

    if (now >= start && now <= end) {
      // Проверяем LocalStorage
      const hasSeenMessage = localStorage.getItem('newYearMessageSeen')
      if (!hasSeenMessage && parseInt(this.user.id) === 18415) {
        this.showMessage = true
        this.runShow()
      }
    }
  }
}
</script>

<template>
  <v-dialog v-model="showMessage" max-width="1060">
    <v-card class="custom-card">
      <v-btn
          @click="closeMessage"
          style="right: 0"
          absolute
          icon
          color="white"
          large>
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="d-flex">
        <div class="cloud"></div>
        <div style="min-width: 300px; max-width: 400px; height: 100%; display: block">
          <v-img src="https://i.pinimg.com/736x/05/b9/d1/05b9d116f80524902ed145f65f0646ac.jpg"/>
        </div>
        <v-card-text class="new-y-text pb-2 pt-4">
          <div class="text-center">
            <div class="text-h5">🎄 С Наступающим Новым Годом 🎄</div>
            <div class="text-h4">Старший Программист!</div>
            <div class="text-h4">2025</div>
          </div>
          <v-divider class="mt-4 mb-4"/>
          Здравствуйте, уважаемая Елена! 🎉<br>
          <br>
          Ваш вклад в развитие нашего проекта нельзя переоценить. Благодаря вашему уникальному навыку обработки данных
          (и терпению к нашим запросам) наш отдел стал не только эффективным, но и невероятно стильным! 💻✨<br>
          <br>
          В честь вашего официального титула <b>"Старший Программист"</b>, мы подготовили для вас праздничный патч:<br>
          <br>
          ✅ Исправлены все баги в настроении.<br>
          ✅ Повышена производительность улыбок до 100%.<br>
          ✅ Добавлен модуль "Праздничное настроение v2025".<br>
          <br>
          <b>Пусть новый год принесет вам:</b><br>
          <br>
          Безлимитное счастье!🎉 Прорывные успехи!📈 И только радостные "заказы"!🦄<br>
          С наилучшими пожеланиями, Ваши коллеги-программисты!👨‍💻<br>
          <br>
          <i>(P.S. Если вы дочитали до этого момента, значит, наша "система" работает идеально. Спасибо за тестирование! 😊)</i><br>
          <div>
            <v-btn class="mt-2" width="100%" color="primary" text @click="closeMessage">Закрити</v-btn>
          </div>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.new-y-text {
  background: white;
  font-size: 14px;
}

.cloud {
  position: absolute;
  z-index: 10000;
  top: 0; /* Позиция тучки по вертикали */
  width: 100%;
  height: 5px;
  background: none;
}
.snowflake {
  position: absolute;
  top: 100%; /* Начальная позиция снега */
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  animation: fall linear infinite;
}

@keyframes fall {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(600px);
    opacity: 0;
  }
}
</style>
