<template>
  <v-navigation-drawer
    v-model="show_aside"
    app
    dark
    right
    clipped
  >
    <v-list
      nav
      dense
    >
<!--      <v-subheader>Меню</v-subheader>-->
      <v-list-item-group>
        <v-list-item @click="$router.push('/dashboard/admin/catalog')">
          <v-list-item-title>Управление каталогом</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$router.push('/dashboard/admin/content')">
          <v-list-item-title>Контент</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$router.push('/dashboard/admin/notification-create')">
          <v-list-item-title>Уведомления</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$router.push('/dashboard/admin/catalog-dropship-b2b')">
          <v-list-item-title>Каталог DROPSHIP B2B</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$router.push('/dashboard/admin/nova-poshta-senders/cylinder')">
          <v-list-item-title>Отправители Новая Почта</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$router.push('/dashboard/admin/rozetka-stores')">
          <v-list-item-title>Магазины Rozetka</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$router.push('/dashboard/admin/payment-contragents/mono')">
          <v-list-item-title>Платежные контрагенты</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: 'SettingsMenuAsideComponent',
  props: ['value'],
  computed: {
    show_aside: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  watch: {
    $route: function (v) {
      this.show_aside = false
    }
  }
}
</script>

<style scoped>

</style>
