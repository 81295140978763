
export const changesLog = {
  data () {
    return {
      versionLog: '0.0.8',
      changes: [
        {
          title: 'Клиенты (ETALON)',
          lines: [
            'Добавлена настройка суммы минимального заказа для клиента'
          ]
        }
      ]
    }
  },
  methods: {
    getLogHTML () {
      let html = ''
      this.changes.forEach(item => {
        html += '<h3 class="mt-2">' + item.title + '</h3>'
        html += '<ul>'
        item.lines.forEach(line => {
          html += '<li>' + line + '</li>'
        })
        html += '</ul>'
      })

      return html
    }
  }
}
